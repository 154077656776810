import { OnlinePipe } from './components/request-call-dialog/online.pipe';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { VgCoreModule } from '@videogular/ngx-videogular/core';
import { VgControlsModule } from '@videogular/ngx-videogular/controls';
import { VgOverlayPlayModule } from '@videogular/ngx-videogular/overlay-play';
import { VgBufferingModule } from '@videogular/ngx-videogular/buffering';

import { AvatarThumbnailComponent } from './components/avatar-thumbnail/avatar-thumbnail.component';
import { RequestCallDialogComponent } from './components/request-call-dialog/request-call-dialog.component';
import { AcceptCallDialogComponent } from './components/accept-call-dialog/accept-call-dialog.component';

import { NgxUploaderModule } from 'ngx-uploader';
import { ImageUploadComponent } from './components/image-upload/image-upload.component';
import { ReadCountsComponent } from './components/read-counts/read-counts.component';
import { MessageRecipientStatusComponent } from './components/message-recipient-status/message-recipient-status.component';
import { MaterialModule } from 'src/material.module';
import { MessageSetOverviewCardComponent } from './components/message-set-overview-card/message-set-overview-card.component';
import { MessageSetDetailCardComponent } from './components/message-set-detail-card/message-set-detail-card.component';
import { TranslateModule } from '@ngx-translate/core';
import { MessageSetPreviewComponent } from './components/message-set-preview/message-set-preview.component';
import { MediaLibraryComponent } from './components/media-library/media-library.component';
import { MediaLibraryImageComponent } from './components/media-library/media-library-image/media-library-image.component';
import { MediaLibraryVideoComponent } from './components/media-library/media-library-video/media-library-video.component';
import { ImageThumbnailsComponent } from './components/media-library/image-thumbnails/image-thumbnails.component';
import { VideoThumbnailsComponent } from './components/media-library/video-thumbnails/video-thumbnails.component';
import { ImageViewerComponent } from './components/image-viewer/image-viewer.component';
import { VideoViewerComponent } from './components/video-viewer/video-viewer.component';
import { MediaLibraryUploadComponent } from './components/media-library/media-library-upload/media-library-upload.component';
import { MediaLibraryImageUploadComponent } from './components/media-library/media-library-image-upload/media-library-image-upload.component';
import { MediaLibraryVideoUploadComponent } from './components/media-library/media-library-video-upload/media-library-video-upload.component';
import { MediaLibraryConfirmDeleteComponent } from './components/media-library/media-library-confirm-delete/media-library-confirm-delete.component';
import { CounterComponent } from './components/counter/counter.component';
import { PincodeComponent } from './components/pincode/pincode.component';
import { SetPasswordFormComponent } from './components/set-password-form/set-password-form.component';
import { ProgressDialogComponent } from './components/progress-dialog/progress-dialog.component';
import { ResidentDashboardCardComponent } from './components/resident-dashboard-card/resident-dashboard-card.component';

@NgModule({
  imports: [
    CommonModule,
    MaterialModule,
    FlexLayoutModule,
    TranslateModule,
    InfiniteScrollModule,
    NgxUploaderModule,
    FormsModule,
    ReactiveFormsModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
  ],
  declarations: [
    RequestCallDialogComponent,
    AcceptCallDialogComponent,
    AvatarThumbnailComponent,
    ImageUploadComponent,
    OnlinePipe,
    ReadCountsComponent,
    MessageRecipientStatusComponent,
    MessageSetOverviewCardComponent,
    MessageSetDetailCardComponent,
    MessageSetPreviewComponent,
    MediaLibraryComponent,
    MediaLibraryImageComponent,
    MediaLibraryVideoComponent,
    ImageThumbnailsComponent,
    VideoThumbnailsComponent,
    ImageViewerComponent,
    VideoViewerComponent,
    MediaLibraryUploadComponent,
    MediaLibraryImageUploadComponent,
    MediaLibraryVideoUploadComponent,
    MediaLibraryConfirmDeleteComponent,
    CounterComponent,
    PincodeComponent,
    SetPasswordFormComponent,
    ProgressDialogComponent,
    ResidentDashboardCardComponent,
  ],
  entryComponents: [AcceptCallDialogComponent, RequestCallDialogComponent],
  exports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    TranslateModule,
    InfiniteScrollModule,
    NgxUploaderModule,
    VgCoreModule,
    VgControlsModule,
    VgOverlayPlayModule,
    VgBufferingModule,
    RequestCallDialogComponent,
    AcceptCallDialogComponent,
    AvatarThumbnailComponent,
    ReadCountsComponent,
    MessageRecipientStatusComponent,
    ImageUploadComponent,
    MessageSetPreviewComponent,
    MediaLibraryComponent,
    MediaLibraryImageComponent,
    MediaLibraryVideoComponent,
    ImageThumbnailsComponent,
    VideoThumbnailsComponent,
    ImageViewerComponent,
    VideoViewerComponent,
    MediaLibraryUploadComponent,
    MediaLibraryImageUploadComponent,
    MediaLibraryVideoUploadComponent,
    MediaLibraryConfirmDeleteComponent,
    CounterComponent,
    PincodeComponent,
    SetPasswordFormComponent,
    ProgressDialogComponent,
    ResidentDashboardCardComponent,
  ],
})
export class SharedModule {}
